.main {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader_div {
  stroke-width: 3;
  stroke: aqua;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* svg style */
.loader_div svg {
  width: 96%;
  height: 25vh;
}

.spinner {
  margin-bottom: 15px;
  width: 24px;
  height: 24px;
  border: 3px solid;
  border-color: rgba(255, 255, 255, .3) transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1.3s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}